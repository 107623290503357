/* GENERAL */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure padding and border are included in element's total width and height */
}

body {
  background-image: url("assets/photo-1604077350837-c7f82f28653f\ \(1\).jpeg");
  background-size: cover;
  background-position: center;
  font-family: "DM Sans", sans-serif;
  color: #333;
}

/* TRANSITION */

a,
.btn {
  transition: color 300ms ease;
}

/* DESKTOP NAV */

nav {
  position: fixed; /* Fix the nav to the top */
  top: 0; /* Position it at the top */
  left: 0; /* Align it to the left edge */
  width: 100%; /* Make it span the full width of the viewport */
  display: flex;
  justify-content: flex-end; /* Align nav to the right */
  align-items: center;
  height: 8vh; /* Adjust height as needed */
  padding: 0 2rem;
  background-color: rgba(
    176,
    57,
    105,
    0.9
  ); /* Slightly transparent background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1000; /* Ensure it stays above other content */
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: auto; /* Push the .logo to the left */
  font-family: "DM Serif Display", serif;
  font-style: normal;
  color: rgb(53, 53, 53);
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
}

.nav-links a:hover {
  color: #d31b83;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-decoration-color: rgb(149, 188, 202);
}

/* HAMBURGER MENU */

#hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  opacity: 0;
  visibility: hidden;
}

.menu-links a {
  display: block;
  padding: 10px 20px;
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.menu-links.open {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* SECTIONS */

section {
  padding: 20vh 2rem 2rem 2rem; /* Add padding for top and sides */
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  min-height: fit-content;
}

/* PROFILE SECTION */

#profile {
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 80vh;
  align-items: center;
  text-align: center;
}

.section__pic-container img {
  border-radius: 50%;
  border: 5px solid #d31b83; /* Color to match theme */
  width: 300px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  object-fit: cover;
}

.section__pic-container img {
  border-radius: 50%;
  border: 5px solid #d31b83; /* Color to match theme */
}

.section__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section__text__p1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-family: "DM Sans", sans-serif;
  font-weight: light;
}

.title {
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  font-family: "DM Serif Display", serif;
  font-style: italic;
  color: #d31b83; /* Color to match theme */
}

.section__text__p2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-family: "DM Serif Display", serif;
  color: #333;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

/* ICONS */

.icon-arrow {
  width: 40px; /* Adjust size as needed */
  height: auto;
  cursor: pointer;
}

.arrow-container {
  text-align: right;
  margin: 20px 0;
}

.icon {
  cursor: pointer;
  height: 2rem;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.1);
}

/* BUTTONS */

.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  font-weight: 600;
  transition: all 300ms ease;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
  font-family: "DM Sans", sans-serif;
}

.btn-color-1,
.btn-color-2 {
  border: #d31b83 0.1rem solid; /* Initial darker border */
  background: transparent; /* Set the background to transparent */
  color: #d31b83; /* Text color */
}

.btn-color-1:hover,
.btn-color-2:hover {
  background: #d31b83;
  color: white;
  cursor: pointer;
  border: #d31b83 0.1rem solid; /* White border on hover */
}

/* ABOUT SECTION*/

#about {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-containers {
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container {
  display: flex;
}

.about-pic {
  border-radius: 2rem;
  border: 0.1rem solid; /* Fixed spacing */
  border-color: black; /* This will override the border color */
  align-items: center;
}

.arrow {
  position: absolute;
  right: -5rem;
  bottom: 2.5 rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  border-radius: 2rem;
  border: 0.1rem solid; /* Fixed spacing */
  border-color: black; /* This will override the border color */
  text-align: center;
}

.section-container {
  gap: 4rem;
  height: 80%;
}

.section_pic-container {
  height: 400px;
  width: 400px;
  margin: auto 0;
}

/* EXPERIENCE SECTION */
#experience {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.experience-sub-title {
  color: black;
  font-weight: 400;
  font-size: 1.75rem;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 2rem;
}

.experience-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.article-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
  max-width: 1200px;
}

article {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 10rem;
  gap: 0.5rem;
  /*hello*/
}

article.icon {
  cursor: default;
}

/* CONTACT SECTION */

#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 40vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin: 2rem auto;
  padding: 2rem;
}

/* HAM MENU*/
